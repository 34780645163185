<template>
  <div class="home">
    <navTable></navTable>
    <div class="swiperOutside">
      <div class="swiper-container" style="width: 100%;height: 100%">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in bannerList" :key="item.id" :title="item.title">
            <img :src="item.url" alt="">
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>

    <div class="redundance">
      <div class="thousand">
        <div class="title">
          <span class="tibiao">新闻资讯</span>
          <div class="dao1hang1" @click="huodong">查看更多</div>
        </div>
        <div class="hyperbolic">
          <div class="smallSw" @click="uabsja">
            <div class="swiper-news" style="width: 100%;height: 100%;position: relative;cursor: pointer;">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in newsList" :key="item.id" :title="item.title"
                     @click="getItem(item)">
                  <img :src="item.topPicture" alt="方块">
                  <div class="xinxian">{{ item.title }}</div>
                </div>
              </div>
              <div class="swiper-zhishi"></div>
            </div>
          </div>
          <div class="xinList">
            <div class="item" v-for="(item,index) in newsList" :key="item.id" :title="item.title"
                 @click="getItem(item)"
                 v-appear-animation="{ appearClass: index===2?'mankuai-in':'slight-in'}">
              <div class="time">
                <p class="ui-article-time2-year">{{ item.year }}</p>
                <span class="ui-article-time2-date">{{ item.date }}</span>
              </div>
              <div class="ui-article-info-box">
                <h5 class="ui-article-title">{{ item.title }}</h5>
                <div class="ui-article-remark-wrap">
                  {{ item.prompt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="activity">
      <div class="huodong">
        <div class="title">
          <span class="tibiao">协会活动</span>
          <div class="dao1hang1" @click="huodong">查看更多</div>
        </div>
        <div class="swiperList">
          <div class="smallhuodong">
            <div class="swiper-huodong" v-appear-animation="{ appearClass: 'silky-in'}"
                 style="width: 100%;height: 100%;position:relative;">
              <div class="swiper-wrapper" style="width: 300px">
                <!-- 在这里添加swiper的内容 -->
                <div class="swiper-slide siand"
                     v-for="item in activity" :key="item.id"
                     @click="getItem(item)"
                >
                  <div class="topimg"><img :src="item.topPicture"></div>
                  <div class="iahdan">
                    <h5 class="baindtia">{{ item.title }}</h5>
                    <div class="ui-article-remark-wrap">
                      {{ item.prompt }}
                    </div>
                    <div class="ui-article-time">{{ item.createT }}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="hengfu">
      <div class="sheniqng">
        <div class="ui-fullmeasure-main-box">
          <span class="ql-editor">发挥桥梁作用、强化服务职能、构建服务平台、创新行业规约</span>
          <span class="gramm">致力于通过技术创新、模式创新，推动互联网、大数据、人工智能与传统商务模式的融合发展</span>
        </div>
        <div class="shen" @click="entryApply">
          申请入会
        </div>
      </div>
    </div>

    <div class="zhengce">
      <div class="iaoeahn">
        <div class="title">
          <span class="tibiao">政策法规</span>
          <div class="dao1hang1" @click="zhengce">查看更多</div>

        </div>
        <div class="shuangbian">
          <div class="shuitem"
               v-for="(item,index) in policyList" :key="index"
               v-appear-animation="{ appearClass: index === 3?'yimiao':'linngdin'}"
               :style="{'z-index':index%2?10:999}"
               @click="getItem(item)"
          >
            <img :src="item.topPicture" alt="">
            <div class="fajadaf">
              <h4 class="daiadnad">
                {{ item.title }}
              </h4>
              <div class="huandahu">
                {{ item.prompt }}
              </div>
              <div class="ui-article-time">{{ item.createT }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="redundance">
      <div class="thousand">
        <div class="title">
          <span class="tibiao">会员动态</span>
          <div class="dao1hang1" @click="huodong">查看更多</div>
        </div>
        <div class="hyperbolic">
          <div class="smallSw" @click="uabsja">
            <div class="swiper-news" style="width: 100%;height: 100%;position: relative;cursor: pointer;">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in memberList" :key="item.id" :title="item.title"
                     @click="getItem(item)">
                  <img :src="item.topPicture" alt="方块">
                  <div class="xinxian">{{ item.title }}</div>
                </div>
              </div>
              <div class="swiper-zhishi"></div>
            </div>
          </div>
          <div class="xinList">
            <div class="item" v-for="(item,index) in memberList" :key="item.id" :title="item.title"
                 @click="getItem(item)"
                 v-appear-animation="{ appearClass: index===2?'mankuai-in':'slight-in'}">
              <div class="time">
                <p class="ui-article-time2-year">{{ item.year }}</p>
                <span class="ui-article-time2-date">{{ item.date }}</span>
              </div>
              <div class="ui-article-info-box">
                <h5 class="ui-article-title">{{ item.title }}</h5>
                <div class="ui-article-remark-wrap">
                  {{ item.prompt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <bottomNav></bottomNav>
  </div>
</template>

<script>
import bottomNav from "@/components/bottomNav.vue"
import navTable from "@/components/navTable.vue"
import Swiper from 'swiper';
import 'swiper/css/swiper.css';

import {getCarouselItem, getlistall} from '@/api/main'

export default {
  name: 'HomeView',
  components: {
    bottomNav,
    navTable,
  },
  data() {
    return {
      newsList: [],
      activity: [],
      policyList: [],
      list: [],
      bannerList: [],
      memberList:[]
    }
  },
  methods: {
    entryApply() {
      this.$router.push({
        path: '/entryApply'
      })
    },
    huodong(){
      this.$router.push({
        path: '/about/news'
      })
    },
    zhengce(){
      this.$router.push({
        path: '/about/ruleLaw'
      })
    },
    uabsja() {
      console.log(this.list)
    },
    getItem(item) {
      this.$router.push({
        name: 'detailsContent',
        query: {
          id: item.id,
        }
      })
    },
    chart(){
      console.log(this.bannerList)
      new Swiper('.swiper-container', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
      });
      new Swiper('.swiper-news', {
        pagination: {
          el: '.swiper-zhishi',
          clickable: true
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
      });
      new Swiper('.swiper-huodong', {
        slidesPerView: 'auto',
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
      });
    }
  },
  created() {

  },
  async mounted() {
    try {
      const [newsResponse, activityResponse, policyResponse,memberListI] = await Promise.all([
        getlistall({ pageNum: 1, pageSize: 6, valid: 1, pType: '新闻资讯' }),
        getlistall({ pageNum: 1, pageSize: 6, valid: 1, pType: '协会活动' }),
        getlistall({ pageNum: 1, pageSize: 6, valid: 1, pType: '政策法规' }),
        getlistall({ pageNum: 1, pageSize: 6, valid: 1, pType: '会员动态' }),
      ]);

      this.newsList = newsResponse.rows.slice(0, 4);
      this.newsList.forEach(item => {
        item.year = item.createT.slice(0,4);
        item.date = item.createT.slice(5);
        item.prompt = item.context.replace(/<[^>]+>|&[^>]+;/g, "").trim();
      });

      this.memberList=memberListI.rows.slice(0, 4);
      this.memberList.forEach(item => {
        item.year = item.createT.slice(0,4);
        item.date = item.createT.slice(5);
        item.prompt = item.context.replace(/<[^>]+>|&[^>]+;/g, "").trim();
      });


      this.activity = activityResponse.rows;
      this.activity.forEach(item => {
        item.prompt = item.context.replace(/<[^>]+>|&[^>]+;/g, "").trim();
      });

      this.policyList = policyResponse.rows.slice(0, 4);
      this.policyList.forEach(item => {
        item.prompt = item.context.replace(/<[^>]+>|&[^>]+;/g, "").trim();
      });
      await getCarouselItem().then(res=>{
        this.bannerList = res.rows
      })


      this.chart();
    } catch (error) {
      console.error('Error fetching data:', error);
      this.$message.error('出错了，请联系管理员！');
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 2000px;

}

.slight-in {
  animation: slightAnimation 0.5s linear;
}

@keyframes slightAnimation {
  from {
    opacity: 0;
    transform: translateX(100%); /* 初始位置，元素向下平移100px */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* 最终位置，元素平移到正常位置 */
  }
}

.silky-in {
  animation: silkyAnimation 1s cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes silkyAnimation {
  from {
    opacity: 0;
    transform: translateX(100%); /* 初始位置，元素向下平移100px */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* 最终位置，元素平移到正常位置 */
  }
}

.mankuai-in {
  animation: mankuaiAnimation 1.5s linear;
}

@keyframes mankuaiAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%); /* 初始位置，元素向下平移100px */
  }
  50% {
    opacity: 0;
    transform: translateX(100%); /* 初始位置，元素向下平移100px */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* 最终位置，元素平移到正常位置 */
  }
}

.yimiao {
  animation: yimiaoAnimation 1s linear;
}

@keyframes yimiaoAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.linngdin {
  animation: linngdinAnimation 0.5s linear;
}

@keyframes linngdinAnimation {
  from {
    opacity: 0;
    transform: translateX(100%); /* 初始位置，元素向下平移100px */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* 最终位置，元素平移到正常位置 */
  }
}


.swiperOutside {
  width: 100%;
  height: 700px;
}

.swiper-slide {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.swiper-button-next, .swiper-button-prev {
  color: white;
}

.swiper-pagination {
  ::v-deep .swiper-pagination-bullet {
    width: 30px !important;
    height: 5px !important;
    border-radius: 0 !important;
  }
}

.redundance {
  width: 100%;
  height: 600px;
  background-color: rgba(247, 247, 247, 0.77);
}

.thousand {
  width: 1300px;
  height: 100%;
  margin: 0 auto;
}

.title {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(216, 218, 220);
}

.tibiao {
  font-size: 25px;
  line-height: 28px;
  color: rgb(26, 28, 29);

}

.daohang {
  display: flex;
  align-items: center;
  color: rgba(58, 56, 56, 1);
  font-size: 14px;
  font-weight: normal;
  gap: 6px;
  font-family: Microsoft Yahei;
}

.hyperbolic {
  width: 100%;
  height: 460px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.smallSw {
  height: 100%;
  flex: 1;
  max-width: 650px;
  overflow: hidden;
}

.xinList {
  flex: 1;
  height: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.swiper-zhishi {
  z-index: 999;
  margin-top: -40px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 60px !important;
  display: flex;
  justify-content: center;
}

.swiper-zhishi .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.swiper-zhishi .swiper-pagination-bullet-active {
  background-color: black;
}

.swiper-news .swiper-slide {
  position: relative;
}

.xinxian {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
  color: #ffffff;
}

.item {
  width: 100%;
  flex: 1;
  max-height: 120px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  cursor: pointer;

  .time {
    color: #666;
    border-right: 1px solid #eee;
    padding: 0 24px 0 10px;
    margin-top: 10px;
  }
}

.ui-article-time2-year {
  font-size: 26px;
  color: #666666;
  margin: 0;
}

.ui-article-time2-date {
  font-size: 16px;
  color: #666666;
}

.ui-article-info-box {
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding-left: 24px;
}

.ui-article-title {
  width: 350px;
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 9em 0 0;
  font-weight: normal;
}

.ui-article-remark-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 10px 0;
  color: #888;
}

.activity {
  width: 100%;
  height: 550px;
  background-color: #ffffff;
}

.huodong {
  width: 1300px;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  margin: 0 auto;
}

.swiperList {
  width: 100%;
  height: 400px;
  padding: 20px 0;
}

.smallhuodong {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.siand {
  width: 300px !important;
  height: 98%;
  margin: 1% 28px 1% 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.topimg {
  width: 100%;
  height: 220px;

  img {
    width: 100%;
    height: 100%;
  }
}

.iahdan {
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
}

.baindtia {
  width: 100%;
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  margin: 0;
}

.ui-article-time {
  color: #999;
  padding: 10px 0;
  line-height: 20px;
  text-align: justify;
}

.hengfu {
  width: 100%;
  height: 200px;
  background-image: url("@/assets/hengdu.png");
  background-size: 100% 100%;
}

.zhengce {
  width: 100%;
  height: 562px;
  overflow: hidden;
}

.iaoeahn {
  width: 1300px;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px 0;
}

.shuangbian {
  width: 100%;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 30px;
}

.shuitem {
  background: #ffffff;
  width: 49%;
  height: 46%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;


  img {
    width: 200px;
    height: 150px;
  }

  .fajadaf {
    height: 100%;
    flex: 1;
    box-sizing: border-box;
    padding: 20px;
    max-width: 380px;
  }


}

.daiadnad {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  margin: 0;
  color: #333333;
  font-size: 17px;
}

.huandahu {
  width: 100%;
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.sheniqng {
  width: 1300px;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}

.ui-fullmeasure-main-box {
  display: flex;
  flex-direction: column;
}

.ql-editor {
  color: rgb(255, 255, 255);
  font-size: 24px;
  line-height: 30px;
}

.gramm {
  line-height: 30px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin-top: 10px;
}

.shen {
  width: 300px;
  height: 50px;
  border: 1px solid rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.14);
  border-radius: 8px;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  font-size: 20px;
  user-select: none;
  cursor: pointer;
}
.dao1hang1{
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
</style>
