<template>
  <div class="navigation">
    <div class="content">
      <div class="leftLogo"   @click="tiaozhun" v-appear-animation="{ appearClass:'sjhaihbd-in'}">
        <img src="@/assets/logo1.png">
      </div>
      <div class="rightTabber">
        <el-menu :default-active="currentPath" class="el-menu-demo" mode="horizontal" router @select="handleSelect">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/about/weInsha">关于协会</el-menu-item>
          <el-menu-item index="/about/news" :route="{path:'/about/news'}">新闻资讯</el-menu-item>
          <el-menu-item index="/about/ruleLaw">政策法规</el-menu-item>
          <el-menu-item index="/about/partyBuilding">党建园地</el-menu-item>
          <el-menu-item index="/about/applyFor">入会申请</el-menu-item>
          <el-menu-item index="/about/contactU">联系我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lasting:true,
    };
  },
  computed: {
    currentPath() {
      console.log(this.$store.state.path,'000--0000')
      return this.$store.state.path;
    }
  },
  methods: {
    handleSelect() {
      console.log(this.$route.path,'999');
    },
    tiaozhun(){
      console.log('0192019201920')
      this.$router.push({
        name:'home'
      })
    }
  },
  mounted() {
    console.log('00111')
    this.lasting=false
  },

}
</script>
<style scoped lang="scss">
.sjhaihbd-in {
  animation: mankuaiAnimation 0.5s linear;
}
@keyframes mankuaiAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.navigation{
  width: 100%;
  //min-width: 1700px;
  height: 100px;
  background-color:#ffffff;
}
.content{
  width: 80%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftLogo{
  //width: 400px;
  height: 60px;
  display: flex;
  line-height:60px;
  font-size: 28px;
  gap: 20px;
  font-weight: 900;
  img{
    height: 100%;
  }
}
::v-deep .el-menu.el-menu--horizontal{
  border: none;
}
::v-deep .el-menu-item{
  font-size: 17px !important;
}
::v-deep .el-submenu__title{
  font-size: 17px !important;
  border-bottom: 2px solid #409EFF;
}
.el-menu--horizontal > .el-menu-item {
  border: none !important;
  text-decoration: none;
}
::v-deep .el-submenu__title {
  border: none !important
}
</style>
