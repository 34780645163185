import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index.js'
import 'normalize.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false

Vue.use(ElementUI);

//监听元素是否进入视图
Vue.directive('appear-animation', {
  bind(el, binding) {
    const options = binding.value || {};
    let hasEntered = false; // 用于追踪元素是否已经进入视图
    let congestion=-1
    // sjhaihbd-in
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !hasEntered) {
          // 获取屏幕宽度
          const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
             console.log(screenWidth,'屏幕宽度')
          if(screenWidth < 1600){
            return ;
          }
          if(options.appearClass==='sjhaihbd-in'&& congestion>1){
            return
          }else{
            // 元素进入视图，添加CSS类触发动画
            el.classList.add(options.appearClass || 'slide-in');
            hasEntered = true; // 更新状态
          }
        } else if (!entry.isIntersecting && hasEntered) {
          // 元素离开视图，移除CSS类
          el.classList.remove(options.appearClass || 'slide-in');
          hasEntered = false; // 更新状态
        }
        congestion++
      });
    }, options.intersectionOptions || {});

    observer.observe(el);
  },
});
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
