<template>
  <div id="app" ref="app">
    <router-view/>
<!--    <div class="suspension"></div>-->
  </div>
</template>
<script>
// import _ from 'lodash'

export default {
  name: "App",
  mounted() {
    this.handleResize()
    this.$store.commit('changeData')
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      const screenWidth = window.innerWidth;
      console.log(screenWidth,'屏幕宽度');
      if(screenWidth<1400){
        const element = document.getElementById('app');
        console.log(element,'element')
        if (element) {
          element.style.minWidth = '1500px';
        }
      }
    }
  }
}
</script>

<style lang="scss">
.rich {
  box-sizing: border-box;
}
//.ql-align-right {
//  text-align: right;
//}
//.ql-align-justify {
//  text-align: justify;
//}
//.ql-align-center {
//  text-align: center;
//}
//.ql-align-left {
//  text-align: left;
//}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font: 14px/1.5 'Microsoft Yahei', Arial, Verdara, '宋体';
  color: #333333;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.suspension{
  width: 50px;
  height: 160px;
  background: pink;
  position: fixed;
  top: 50%;
  margin-top:-80px;
  right: 10px;
  z-index: 9999;
}
</style>
