import request from '@/utils/request'


//列表
export function getlistall(query) {
    return request({
        url: '/publish/content/list',
        method: 'get',
        params: query
    })
}



//单个详情
export function getlistItem(id) {
    return request({
        url: '/publish/content/'+ id,
        method: 'get',
    })
}

//单个详情
export function getApplyItem() {
    return request({
        url: '/publish/content/list?pType=入会流程',
        method: 'get',
    })
}

//单个详情
export function getinformationItem() {
    return request({
        url: '/association/info/1',
        method: 'get',
    })
}
//申请入会
export function addEntryItem(data) {
    return request({
        url: '/join/person',
        method: 'post',
        data
    })
}
//轮播图
export function getCarouselItem() {
    return request({
        url: '/system/banner/list',
        method: 'get',
    })
}
