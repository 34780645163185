import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store/index"
Vue.use(VueRouter)

// 解决NavigationDuplicated报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    children:[
      {
        path: 'weInsha',
        name: 'weInsha',
        meta:{title:'新闻资讯'},
        component: () => import(/* webpackChunkName: "about" */ '../views/weInsha.vue')
      },
      {
        path: 'news',
        name: 'news',
        meta:{title:'新闻资讯'},
        component: () => import(/* webpackChunkName: "about" */ '../views/newsInfo.vue')
      },{
        path: 'ruleLaw',
        name: 'ruleLaw',
        meta:{title:'政策法规'},
        component: () => import(/* webpackChunkName: "about" */ '../views/ruleLaw.vue')
      },{
        path: 'partyBuilding',
        name: 'partyBuilding',
        meta:{title:'党建园地'},
        component: () => import(/* webpackChunkName: "about" */ '../views/partyBuilding.vue')
      },{
        path: 'applyFor',
        name: 'applyFor',
        meta:{title:'入会申请'},
        component: () => import(/* webpackChunkName: "about" */ '../views/applyFor.vue')
      },{
        path: 'contactU',
        name: 'contactU',
        meta:{title:'联系我们'},
        component: () => import(/* webpackChunkName: "about" */ '../views/contactU.vue')
      },
      {
        path: '/detailsContent',
        name: 'detailsContent',
        meta:{title:'详情'},
        component: () => import(/* webpackChunkName: "about" */ '../views/detailsContent.vue')
      },
      {
        path: '/entryApply',
        name: 'entryApply',
        meta:{title:'申请入会'},
        component: () => import(/* webpackChunkName: "about" */ '../views/entryApply.vue')
      }
    ]
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 在这里执行你的逻辑，例如打印当前路由信息
  console.log('Current route:', to.path);
  store.commit('increment',to.path)
  // 继续路由跳转
  next();
});


export default router
