import Vue from 'vue'
import Vuex from 'vuex'
import {getinformationItem} from "@/api/main";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    path:'/',
    dataObg:{}
  },
  getters: {
  },
  mutations: {
    increment (state,value) {
      console.log('value',value)
      state.path = value
    },
    changeData (state) {
      getinformationItem().then(res=>{
        state.dataObg=res.data
        console.log(res,'11111111111111111111111111111111111111111111111')
      })
    }
  },
  actions: {
  },
  modules: {
  }
})
