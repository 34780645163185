<template>
  <div class="package">
    <div class="region" >
      <div class="oneList">
        <div class="ql-editor1" v-appear-animation="{ appearClass: 'slight-in'}">关于协会</div>
        <div class="aos-animate" v-appear-animation="{ appearClass: 'slide-in'}">
          <div class="event point">
            <a  class="event" @click="rouPath('weInsha')">协会概况</a>
          </div>
          <div class="event point">
            <a @click="rouPath('weInsha')" class="event">协会章程</a>
          </div>
          <div class="event point">
            <a @click="rouPath('weInsha')" class="event">协会大事记</a>
          </div>
          <div class="event point">
            <a @click="rouPath('weInsha')" class="event">组织机构</a>
          </div>
          <div class="event point">
            <a @click="rouPath('weInsha')" class="event">会员单位</a>
          </div>
        </div>
      </div>
      <div class="oneList ui-watch">
        <div class="ql-editor1" v-appear-animation="{ appearClass: 'slight-in'}">新闻资讯</div>
        <div class="aos-animate" v-appear-animation="{ appearClass: 'slide-in'}">
          <div class="event point">
            <a @click="rouPath('news')" class="event">协会公告</a>
          </div>
          <div class="event point">
            <a @click="rouPath('news')" class="event">协会活动</a>
          </div>
          <div class="event point">
            <a @click="rouPath('news')" class="event">行业资讯</a>
          </div>
          <div class="event point">
            <a @click="rouPath('news')" class="event">会员动态</a>
          </div>
        </div>
      </div>
      <div class="contactDist">
        <div class="ql-editor1" v-appear-animation="{ appearClass: 'slight-in'}">联系我们</div>
        <div class="callNum">
          <div class="frame callIcon" v-appear-animation="{ appearClass: 'slight-in'}">
          </div>
          <div class="frame-da" v-appear-animation="{ appearClass: 'slight-in'}">电话：{{$store.state.dataObg.landline}}</div>
        </div>
        <div class="callNum">
          <div class="frame contacts" v-appear-animation="{ appearClass: 'slight-in'}">
          </div>
          <div class="frame-da" v-appear-animation="{ appearClass: 'slide-in'}">联系人：{{$store.state.dataObg.callPerson}}</div>
        </div>
        <div class="sirn">
          <div class="frame contacts1" style="margin-top: 9px" v-appear-animation="{ appearClass: 'slight-in'}">
          </div>
          <div class="frame-da sirn" v-appear-animation="{ appearClass: 'slide-in'}">地址：{{$store.state.dataObg.address}}</div>
        </div>
      </div>
      <div class="interestFollow" v-appear-animation="{ appearClass: 'slide-in'}">
          <div class="colour" key="1" >
            <div class="huana">
              <img src="@/assets/erweim.png" />
            </div>
            <p class="jiesao" v-appear-animation="{ appearClass: 'slight-in'}">扫描添加联系方式</p>
          </div>
<!--          <div class="colour" key="2">-->
<!--            <div class="huana"></div>-->
<!--            <p class="jiesao" v-appear-animation="{ appearClass: 'slight-in'}">这里是介绍</p>-->
<!--          </div>-->
      </div>
    </div>
    <div class="ui-copy-right">
      <div class="ui-copy-right-flex">
        <span class="ui-copy-right-item">版权所有：{{$store.state.dataObg.allRightsReserved}}</span>
        <span class="ui-copy-right-item">
          备案号：
         <a href="https://beian.miit.gov.cn/" class="congada">{{$store.state.dataObg.recordNumber}}</a>
        </span>
        <span class="ui-copy-right-item">
          技术支持：
         <a href="https://www.yxtx.vip/" class="congada">天津市易心天下网络科技有限公司</a>
        </span>

      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'HomeView',
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    rouPath(e){
      this.$router.push({
        name:e
      })
    }
  }
}
</script>
<style scoped lang="scss">
.slide-in {
  animation: slideInAnimation 0.5s linear;
}
@keyframes slideInAnimation {
  from {
    opacity: 0 ;
    transform: translateY(50px); /* 初始位置，元素向下平移100px */
  }
  to {
    opacity: 1 ;
    transform: translateY(0); /* 最终位置，元素平移到正常位置 */
  }
}
.slight-in{
  animation: slightAnimation 0.5s linear;
}

@keyframes slightAnimation {
  from {
    opacity: 0 ;
    transform: translateY(20px); /* 初始位置，元素向下平移100px */
  }
  to {
    opacity: 1 ;
    transform: translateY(0); /* 最终位置，元素平移到正常位置 */
  }
}
.package {
  width: 100%;
  //min-width: 1700px;
  height: 330px;
  background: url('@/assets/bottomBack.png') center top repeat-x;
}
.region {
  width: 80%;
  margin: 0 auto;
  height: 280px;
  display: flex;
  color: rgb(160, 162, 164);
  box-sizing: border-box;
  padding-top: 30px;
  gap: 30px;
}

.ui-copy-right {
  width: 100%;
  min-height: 50px;
}

.ui-copy-right-flex {
  width: 1200px;
  min-height: 50px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  gap: 14px;
  .ui-copy-right-item{
    color: rgb(153, 153, 153);
    display: flex;
    align-items: center;
  }
}

.oneList {
  width: 130px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ql-editor1 {
  height: 38px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 17px;
  text-align: left;
  word-break: break-all;
  font-weight: 500;
}

.aos-animate {
  width: 100%;
  height: 100%;
}

.event {
  line-height: 36px;
  font-size: 15px;
  color: rgb(160, 162, 164);
  text-align: left;
  flex: 1;
  text-decoration: none;
}

.event:hover {
  color: rgb(126, 129, 131);
}

.point {
  margin-bottom: 6px;
  cursor: pointer;
}

.oneList .point:last-child {
  margin-bottom: 0;
}

.oneList .point:first-child {
  margin-top: 6px;
}

.contactDist {
  width: 350px;
  height: 100%;
  margin-left: 200px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.callNum {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.frame{
  width: 28px;
  height: 28px;
}
.callIcon:before{
  content: '';
  width: 28px;
  height: 28px;
  display: block;
  background:url("@/assets/call.png") no-repeat;
  background-size:100% 100%;
}
.contacts:before{
  content: '';
  width: 28px;
  height: 28px;
  display: block;
  background:url("@/assets/contacts.png") no-repeat;
  background-size:100% 100%;
}
.contacts1:before{
  content: '';
  width: 28px;
  height: 28px;
  display: block;
  background:url("@/assets/quality,Q_100.png") no-repeat;
  background-size:100% 100%;
}
.frame-da{
  color: rgb(160, 162, 164);
  font-size: 15px;
}
.sirn{
  width: 100%;
  height: 76px;
}
.sirn{
  display: flex;
  gap: 10px;
  line-height: 38px;
}
.interestFollow{
  //width: 400px;
  height: 100%;
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  //opacity: 0;
}
.colour{
  width: 158px;
}
.huana{
  width: 158px;
  height: 158px;
  background-color: blue;
  img{
    width: 100%;
    height: 100%;
  }
}
.jiesao{
  margin: 0;
  height: 38px;
  text-align: center;
  line-height: 38px;
}
.congada{
  line-height: 36px;
  font-size: 15px;
  text-align: left;
  flex: 1;
  text-decoration: none;
  margin-left: 6px;
  color: inherit;
}
</style>
